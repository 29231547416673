import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { FormModule } from '../form/form.module';
import { ErrorComponent } from './components/error/error.component';
import { BlankComponent } from './components/layouts/blank/blank.component';
import { FullComponent } from './components/layouts/full/full.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { ActionButtonDirective } from './directives/action-button/action-button.directive';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './directives/accordion';
import { AssignRoleComponent } from './modals/assign-role/assign-role.component';
import { StatusComponent } from './modals/status/status.component';
import { StatusRoleComponent } from './modals/status-role/status-role.component';
import { BreadcrumbComponent } from './components/layouts/breadcrumb/breadcrumb.component';
import { ResentVerificationComponent } from './modals/resent-verification/resent-verification.component';
import { LearningMetricsModalComponent  } from './modals/learning-metrics/learning-metrics-modal.component';
import { AddUsersComponent } from './modals/add-users/add-users.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AddCourseComponent } from './modals/add-course/add-course.component';
import { AddModuleComponent } from './modals/add-module/add-module.component';
import { BannerComponent } from './components/layouts/banner/banner.component';
import { TruncateTextPipe } from './pipes/truncate-text/truncate-text.pipe';
import { AddCooperativeComponent } from './modals/add-cooperative/add-cooperative.component';
import { GridTemplateComponent } from './components/layouts/grid-template/grid-template.component';
import { ArchiveOrgComponent }  from './modals/archive-org/archive-org.component';
import { ChooseCourseComponent } from './components/choose-course/choose-course.component';
import { AddUserToCooperativeComponent } from './modals/add-user-to-cooperative/add-user-to-cooperative.component';
import { SelectTrainingComponent } from './modals/select-training/select-training.component';
import { EntityCourseModalComponent } from './modals/entity-course-modal/entity-course-modal.component';
import { AddProductComponent } from './modals/add-product/add-product.component';
import { AddCourseVersionComponent } from './modals/add-course-version/add-course-version.component';
import { NgxPermissionsDirective, NgxPermissionsModule } from 'ngx-permissions';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AddEnterpriseSubscriptionComponent } from './modals/add-enterprise-subscription/add-enterprise-subscription.component';
import { TruncateWithCountPipe } from './pipes/tuncate-with-count/truncate-with-count.pipe';
import { AddCourseCatalogComponent } from './modals/add-course-catalog/add-course-catalog.component';
import { AllUsersEditComponent } from 'src/app/routes/users/all-users/all-users-edit/all-users-edit.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { SnackbarComponent } from './components/layouts/snackbar/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { UserResetPasswordComponent } from './modals/user-reset-password/user-reset-password.component';
import { FilterIconComponent } from './components/layouts/filter-icon/filter-icon.component';
import { AddFileModalComponent } from './modals/add-file-modal/add-file-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AddAppointedCertificatesComponent } from './modals/add-appointed-certificates/add-appointed-certificates.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AddMessageComponent } from './modals/add-message/add-message.component';
import { BannerMessageEditComponent } from './modals/banner-message-edit/banner-message-edit.component';
import { DashboardAdMessageEditComponent } from './modals/dashboard-ad-message-edit/dashboard-ad-message-edit.component';
import { LoginMessageEditComponent } from './modals/login-message-edit/login-message-edit.component';



@NgModule({
  declarations: [
    ErrorComponent,
    BlankComponent,
    FullComponent,
    HeaderComponent,
    SidebarComponent,
    ActionButtonDirective,
    AccordionDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AssignRoleComponent,
    StatusRoleComponent,
    StatusComponent,
    BreadcrumbComponent,
    ResentVerificationComponent,
    LearningMetricsModalComponent,
    AddUsersComponent,
    FooterComponent,
    AddCourseComponent,
    AddModuleComponent,
    BannerComponent,
    TruncateTextPipe,
    TruncateWithCountPipe,
    AddCooperativeComponent,
    GridTemplateComponent,
    ArchiveOrgComponent,
    ChooseCourseComponent,
    AddUserToCooperativeComponent,
    SelectTrainingComponent,
    EntityCourseModalComponent,
    AddProductComponent,
    AddCourseVersionComponent,
    UnauthorizedComponent,
    AddEnterpriseSubscriptionComponent,
    AddCourseCatalogComponent,
    AllUsersEditComponent,
    ConfirmModalComponent,
    SnackbarComponent,
    UserResetPasswordComponent,
    FilterIconComponent,
    AddFileModalComponent,
    AddAppointedCertificatesComponent,
    SpinnerComponent,
    DashboardAdMessageEditComponent,
    BannerMessageEditComponent,
    AddMessageComponent,
    LoginMessageEditComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormModule,
    NgxPermissionsModule,
    MatIconModule,
    FileUploadModule
  ],
  exports:[
    MaterialModule,
    FormModule,
    TruncateTextPipe,
    TruncateWithCountPipe,
    GridTemplateComponent,
    ChooseCourseComponent,
    NgxPermissionsModule,
    MatIconModule,
    FilterIconComponent,
    BannerMessageEditComponent,
    DashboardAdMessageEditComponent,
    LoginMessageEditComponent

  ]
})
export class SharedModule { }
