import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DashboardCharts } from './dashboard.model';

export interface DashboardChartsState extends EntityState<DashboardCharts> {
  dailyData?: DashboardCharts; // To hold daily data
  trendData?: DashboardCharts;  // To hold trend data
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboard' })
export class DashboardChartsStore extends EntityStore<DashboardChartsState> {
  constructor() {
    super();
  }

  setDailyData(dailyData: DashboardCharts) {
    this.update({ dailyData });
  }

  setTrendData(trendData: DashboardCharts) {
    this.update({ trendData });
  }
}
